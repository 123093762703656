const checkConditions = (data = {}, colParams, showInputs, req) => {
  if (data.category) {
    const inputsAfterCategory = [
      'comments',
      'revenue',
      'cost',
      'income_reserve',
      'realized_revenue',
      'realized_reserve',
      'remained_revenue',
      'prepayment_cost',
      'prepayment_year',
      'VR_cost',
      'total_costs',
      'profit',
      'real_profit',
      'real_turnover'
    ]
    inputsAfterCategory.forEach(el => showInputs[el] = true)

    // Model - zależne od kategorii

    if (['Afiliacja', 'Afiliacja+', 'Mailing'].includes(data.category)) {
      showInputs.model = true
      req.push('model')
      if (data.model && (!['CPM', 'FF'].includes(data.model))) {
        showInputs.system = true
        showInputs.programId = true
        req.push('system', 'programId')
      } else {
        showInputs.system = false
        showInputs.programId = false
        req = req.filter(el => !['system', 'programId'].includes(el))
        delete data.system
        delete data.programId
      }
    } else {
      showInputs.model = false
      req = req.filter(el => !['model'].includes(el))
      delete data.model
    }

    // Nazwa dostawcy - zależne od kategorii

    if (['Pozostałe', 'IT', 'Analityka', 'Grafika', 'Projekty', 'Refaktura', 'SEO', 'Content'].includes(data.category) ||
      (data.category === 'Mailing' && ['CPM', 'FF'].includes(data.model))) {
      showInputs.supplier = true
      if (data.category === 'Mailing' && ['CPM', 'FF'].includes(data.model)) {
        req.push('supplier')
      } else {
        if (!!data.cost && data.cost !== '0' && data.cost !== '0.00') {
          req.push('supplier')
        } else {
          req = req.filter(el => !['supplier'].includes(el))
        }
      }
    } else {
      showInputs.supplier = false
      req = req.filter(el => !['supplier'].includes(el))
      delete data.supplier
    }

    // Data walidacji - zależne od modelu

    if (['CPA', 'CPL', 'CPS', 'CPO'].includes(data.model) && data.system === 'Ingenious') {
      showInputs.validDate = true
      req.push('validDate')
    } else {
      showInputs.validDate = false
      req = req.filter(el => !['validDate'].includes(el))
      delete data.validDate
    }

    // Powierzchnia
    if (['PPC', 'RTB', 'Monetyzacja', 'IT', 'Marketplace'].includes(data.category) || parseInt(data.resp_depart, 10) === 444) {
      showInputs.space = true
      req.push('space')
      if (data.category === 'PPC' && (['Facebook', 'Google', 'Tik Tok', 'Bing'].includes(data.space))) {
        showInputs.account_ID = true
        req.push('account_ID')
      } else {
        showInputs.account_ID = false
        req = req.filter(el => !['account_ID'].includes(el))
        delete data.account_ID
      }
    } else {
      showInputs.space = false
      showInputs.account_ID = false
      req = req.filter(el => !['space', 'account_ID'].includes(el))
      delete data.space
    }

    // Szacowany koszt doemisji i Rok przedpłaty zależne od Doemisja przychód

    if (data.realized_revenue || data.remained_revenue || data.prepayment_cost || data.prepayment_year) {
      req.push('realized_revenue', 'remained_revenue', 'prepayment_cost', 'prepayment_year')
    } else {
      req = req.filter(el => !['realized_revenue', 'remained_revenue', 'prepayment_cost', 'prepayment_year'].includes(el))
    }

    // Rezerwa na przychody

    if (data.income_reserve || data.realized_reserve) {
      req.push('income_reserve', 'realized_reserve')
    } else {
      req = req.filter(el => !['income_reserve', 'realized_reserve'].includes(el))
    }
  } else {
    // inputsAfterCategory.map(el => showInputs[el] = false);
    showInputs = Object.entries(colParams).reduce((a, el, key) => { a[el[0]] = key < 16; return (a) }, {})
    const removeData = Object.keys(colParams).filter((el, key) => key >= 13)
    removeData.map(el => data[el] = '')
  }

  return ({
    showInputs,
    requiredForSettleButton: req
  })
}

function checkValidation (data, requiredForSaveButton, requiredForSettleButton, fromTable) {
  let formValid
  if (data.settled === 'tak' || fromTable) {
    formValid = requiredForSettleButton.every(prop => data[prop] && (data[prop] !== 'undefined' || data[prop] !== '' || data[prop].length > 0))
  } else {
    formValid = requiredForSaveButton.every(prop => data[prop] && (data[prop] !== 'undefined' || data[prop] !== '' || data[prop].length > 0))
  }
  return formValid
}

module.exports = {
  checkConditions,
  checkValidation
}
