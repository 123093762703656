const settlementSource = {
  model: ['CPA', 'CPC', 'CPL', 'CPM', 'CPO', 'CPS', 'FF'],
  category: [
    'Afiliacja',
    'Afiliacja+',
    'Analityka',
    'Content',
    'Grafika',
    'IT',
    'Mailing',
    'Monetyzacja',
    'Pozostałe',
    'PPC',
    'Projekty',
    'Refaktura',
    'RTB',
    'SEO',
    'Marketplace'
  ],
  currency: ['PLN', 'EUR', 'CZK', 'USD'],
  system: [
    'Ingenious',
    'SalesMedia',
    'NetSales'
  ],
  spaces: {
    // SEM
    26: [
      'Google',
      'Adkontekst',
      'Allegro',
      'Baidu',
      'Bing',
      'BusinessClick',
      'Facebook',
      'Instagram',
      'LinkedIn',
      'Nasza Klasa',
      'Seznam',
      'Twitter',
      'Vkontakte',
      'Yandex',
      'Naver',
      'Snapchat',
      'Fasttony',
      'Yahoo Japan',
      'Apple',
      'Tik Tok',
      'Huawei Ads',
      'Campaign Manager 360',
      'Search Ads 360'
    ],
    // MailSales
    256: [
      'iKomunikator 1',
      'iKomunikator 2',
      'iKomunikator 3',
      'iKomunikator 4',
      'iKomunikator 5',
      'iKomunikator 6',
      'iKomunikator 7',
      'iKomunikator 8',
      'iKomunikator 9',
      'iKomunikator 10',
      'iKomunikator 11',
      'iKomunikator 12', // DigitalContact
      'iKomunikator 13', // Redlink
      'iKomunikator 14', // ExpertSender
      'iKomunikator 15',
      'iKomunikator 16',
      'iKomunikator 17',
      'iKomunikator 18',
      'iKomunikator 19',
      'iKomunikator 20',
      'iKomunikator 21',
      'iKomunikator 22',
      'iKomunikator 23',
      'iKomunikator 24',
      'iKomunikator 25',
      'iKomunikator 26',
      'iKomunikator 27',
      'iKomunikator 28',
      'iKomunikator 29',
      'iKomunikator 30',
      'iKomunikator 31',
      'iKomunikator 32',
      'iKomunikator 33',
      'iKomunikator 34',
      'iKomunikator 35',
      'iKomunikator 36',
      'iKomunikator 37',
      'iKomunikator 38',
      'iKomunikator 39',
      'iKomunikator 40'
    ],
    // Programatic
    174: [
      'SM 13641 AN',
      'IG 390386 Mexad',
      'IG 389294 Audience Network',
      'IG 377004 BidLab',
      'AdForm',
      'DBM',
      'DCM',
      'DSP',
      'Pozostałe'
    ],
    // IT
    7: [
      'CDP',
      'Traffic',
      'Pozostałe'
    ],
    // Traffic
    338: [
      'CDP',
      'Traffic',
      'Pozostałe'
    ],
    // Marketplace
    442: [
      'Allegro',
      'Amazon',
      'eBay',
      'Erli',
      'Empik',
      'Zalando',
      'Shopee',
      'Ceneo',
      'Morele',
      'Domodi',
      'Homebook'
    ],
    // Paid Social
    444: [
      'Linkedin',
      'Facebook',
      'Tiktok',
      'Twitter',
      'Pinterest',
      'Pozostałe'
    ],
    // Performance
    354: [
      'iKomunikator 1',
      'iKomunikator 2',
      'iKomunikator 3',
      'iKomunikator 4',
      'iKomunikator 5',
      'iKomunikator 6',
      'iKomunikator 7',
      'iKomunikator 8',
      'iKomunikator 9',
      'iKomunikator 10',
      'iKomunikator 11',
      'iKomunikator 12', // DigitalContact
      'iKomunikator 13', // Redlink
      'iKomunikator 14', // ExpertSender
      'iKomunikator 15',
      'iKomunikator 16',
      'iKomunikator 17',
      'iKomunikator 18',
      'iKomunikator 19',
      'iKomunikator 20',
      'iKomunikator 21',
      'iKomunikator 22',
      'iKomunikator 23',
      'iKomunikator 24',
      'iKomunikator 25',
      'iKomunikator 26',
      'iKomunikator 27',
      'iKomunikator 28',
      'iKomunikator 29',
      'iKomunikator 30',
      'iKomunikator 31',
      'iKomunikator 32',
      'iKomunikator 33',
      'iKomunikator 34',
      'iKomunikator 35',
      'iKomunikator 36',
      'iKomunikator 37',
      'iKomunikator 38',
      'iKomunikator 39',
      'iKomunikator 40'
    ]
  }
}

const formSettlement = {
  _id: {
    pl: 'ID'
  },
  settled: {
    pl: 'Rozliczona',
    col: 1,
    stickyCol: true,
    frozen: true,
    width: 100,
    req2: true
  },
  company: {
    pl: 'Zleceniodawca',
    card: 1,
    col: 1,
    stickyCol: true,
    type: 'select',
    source: 'companies',
    frozen: true,
    req1: true
  },
  client: {
    pl: 'Klient',
    card: 1,
    col: 1,
    frozen: true,
    req1: true
  },
  order_name: {
    pl: 'Kampania',
    card: 1,
    col: 1,
    frozen: true,
    req1: true
  },
  order_id: {
    pl: 'Nr zlecenia',
    card: 1,
    col: 1,
    stickyCol: true,
    width: 120,
    frozen: true,
    req1: false
  },
  job_id: {
    pl: 'Nr podzlecenia',
    col: 1,
    frozen: true,
    width: 120,
    req1: false
  },
  main_depart: {
    pl: 'Dział główny',
    card: 1,
    col: 2,
    type: 'select',
    source: 'departments',
    req1: true
  },
  main_person: {
    pl: 'Opiekun Klienta',
    card: 1,
    col: 2,
    type: 'select',
    source: 'users',
    req1: true
  },
  from_depart: {
    pl: 'Dział zlecający',
    card: 1,
    col: 2,
    type: 'select',
    source: 'departments',
    req1: true
  },
  from_person: {
    pl: 'Osoba zlecająca',
    card: 1,
    col: 2,
    type: 'select',
    source: 'users',
    req1: true
  },
  resp_depart: {
    pl: 'Dział realizujący',
    card: 1,
    col: 2,
    stickyCol: true,
    type: 'select',
    source: 'departments',
    req1: true
  },
  resp_person: {
    pl: 'Osoba realizująca',
    card: 1,
    col: 2,
    type: 'select',
    source: 'users',
    req1: true
  },
  category: {
    pl: 'Kategoria',
    card: 2,
    col: 3,
    stickyCol: true,
    width: 110,
    type: 'select',
    source2: 'category',
    req1: true,
    tooltip: 'Wybierz typ działań.'
  },
  month: {
    pl: 'Miesiąc',
    card: 2,
    col: 3,
    stickyCol: true,
    type: 'select',
    width: 100,
    req1: true,
    tooltip: 'Okres, w którym były realizowane działania.'
  },
  comments: {
    pl: 'Dodatkowe uwagi',
    card: 2,
    col: 3,
    tooltip: 'Tu możesz zawrzeć informacje, które pozwolą łatwiej zidentyfikować rozliczenie. Pole do celów wewnętrznych, dla Twojej wygody 😊'
  },
  model: {
    pl: 'Model',
    card: 2,
    col: 3,
    type: 'select',
    source2: 'model'
  },
  validDate: {
    pl: 'Data walidacji',
    card: 2,
    col: 3,
    type: 'date'
  },
  system: {
    pl: 'System',
    card: 2,
    col: 3,
    type: 'select',
    source2: 'system'
  },
  programId: {
    pl: 'ID programu',
    card: 2,
    col: 3,
    type: 'select'
  },
  supplier: {
    pl: 'Nazwa dostawcy',
    card: 2,
    col: 3,
    tooltip: 'Uzupełnij, w przypadku ponoszenia kosztu od zewnętrznego dostawcy.'
  },
  space: {
    pl: 'Powierzchnia',
    card: 2,
    col: 3,
    type: 'select',
    source2: 'spaces'
  },
  income_reserve: {
    pl: 'Rezerwa na przychody',
    card: 3,
    col: 5,
    type: 'amount',
    tooltip: 'Wartość realizacji z rozliczanego miesiąca za którą Klient będzie fakturowany w kolejnych miesiącach.'
  },
  realized_reserve: {
    pl: 'Zafakturowany przychód z rezerwy',
    card: 3,
    col: 5,
    type: 'amount',
    tooltip: 'Tu wprowadź kwotę którą fakturujesz za działania z poprzednich miesięcy. Tj. kwotę z założonej wcześniej Rezerwy na przychody.'
  },
  realized_revenue: {
    pl: 'Zrealizowany przychód z przedpłaty',
    card: 5,
    col: 5,
    type: 'amount',
    tooltip: 'Wprowadź jeżeli realizujesz działania z przedpłaty klienta i nie wystawiasz mu faktury. Uwaga – nigdy nie może być uzupełnione jednocześnie pole Przychód oraz Zrealizowany przychód z przedpłaty.'
  },
  remained_revenue: {
    pl: 'Pozostały przychód',
    card: 5,
    col: 5,
    stickyCol: true,
    type: 'amount',
    tooltip: 'Pole, w którym wpisujemy kwotę jaką klient przedpłacił lub jaka została mu z poprzednich miesięcy po uwzględnieniu realizacji z pola "Zrealizowany przychód z przedpłaty".'
  },
  prepayment_cost: {
    pl: 'Szacowany koszt do emisji',
    card: 5,
    col: 5,
    type: 'amount',
    tooltip: 'Wpisz szacowaną przez Ciebie kwotę kosztu która będzie potrzebna do zrealizowania pozostałej klientowi przedpłaty.'
  },
  prepayment_year: {
    pl: 'Rok przedpłaty',
    card: 5,
    col: 5,
    type: 'select',
    tooltip: 'Wpisz w którym roku klient przedpłacił nam ten budżet.'
  },
  account_ID: {
    pl: 'ID konta',
    card: 2,
    col: 3
  },
  lastUpdate: {
    pl: 'Ostatnia aktualizacja',
    col: 3
  },
  revenue: {
    pl: 'Przychód',
    card: 4,
    col: 4,
    stickyCol: true,
    type: 'amount',
    width: 100,
    editable: true,
    req2: true,
    tooltip: 'Wartość zrealizowanego budżetu mediowego (wraz z prowizją oraz wszystkimi opłatami dodatkowymi).'
  },
  cost: {
    pl: 'Koszt',
    card: 4,
    col: 4,
    stickyCol: true,
    type: 'amount',
    width: 100,
    editable: true,
    req2: true,
    tooltip: 'Tu uwzględnij zewnętrzny koszt realizacji (np. koszt Google czy freelancera). Nie uwzględniaj kosztów wewnętrznych takich jak RBH itp.'
  },
  VR_cost: {
    pl: 'Koszt KB',
    card: 4,
    col: 4,
    type: 'amount',
    width: 100,
    tooltip: 'Pole uzupełniane przez dział Finance.'
  },
  total_costs: {
    pl: 'Koszty łącznie',
    card: 4,
    col: 4,
    type: 'amount',
    width: 120,
    tooltip: 'Suma kosztu oraz kosztu KB'
  },
  profit: {
    pl: 'Zysk',
    card: 4,
    col: 4,
    type: 'amount',
    width: 100,
    tooltip: 'Pole które uwzględnia wyłącznie pola Przychód i Koszt. Nie uwzględnia za to przedpłat i innych przesunięć.'
  },
  real_profit: {
    pl: 'Realny Zysk',
    card: 4,
    col: 4,
    stickyCol: true,
    type: 'amount',
    width: 120,
    tooltip: 'Realnie osiągnięty wynik z uwzględnieniem, przedpłat, rezerw i przesunięć. W tym polu powinieneś widzieć faktycznie osiągniętą marżę na działaniach.'
  },
  real_turnover: {
    pl: 'Realny Obrót',
    card: 4,
    col: 4,
    stickyCol: true,
    type: 'amount',
    width: 120,
    tooltip: 'Realnie osiągnięty przychód od klienta z uwzględnieniem, przedpłat, rezerw i przesunięć. W tym polu powinieneś widzieć faktycznie zrealizowany budżet.'
  },
  correction: {
    pl: 'Korekta'
  }
}

module.exports = {
  settlementSource,
  formSettlement
}
